
export const routeNames={
  inquiry:'/dashboard/inquiries',
    newapplication:'/dashboard/new-application',
    sendToCustomer:'/dashboard/sent-to-customer',
    submitted:'/dashboard/submitted',
  customerApproval: '/dashboard/customer-approval',
    customerAccepted:"/dashboard/customer-accepted",
    Completed:'/dashboard/completed', 
    OtpVerify:'/otp-verify',
  newInquiry:'/dashboard/inquiry/company-details',
    newRequest:'/new-request',
  settings: '/settings',
  login: "/login",
  internalError: "/something-went-wrong",
  dashboard:"/dashboard"
  // newInquiry: '/newinquiry'
  }